import React, { useEffect, useRef, useState } from 'react';
import 'pages/vendorDetail/_vendorDetail.scss'
import { hooks, labels, local, routes, utils } from "common";
import { api } from "api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconCheckCircle, IconLocation, IconTelephone } from "assets/icons";
import { Basket, LocationNotFoundImage } from "assets/images";
import { LoaderBtn, Map, MenuCarousel, MenuLink, ReviewSection, Schedule, VendorInfo } from 'components';
import { Breadcrumb, Placeholder } from "react-bootstrap";
import { useLoginContext } from "contexts";
import { useDispatch, useSelector } from "react-redux";
import { generalExtraActions } from "reduxStore/actions";
import { authActions } from "../../reduxStore/reducers/authSlice";
import { generalActions } from "../../reduxStore/reducers/generalSlice";


function VendorDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {showLoginModal} = useLoginContext();
  const {reviews, loading: generalLoading} = useSelector((state) => state.general);
  const [pageNumber, setPageNumber] = useState(1);
  const [shop, setShop] = useState(null);
  const {id: shopId} = useParams();
  const reviewsRef = useRef(null);
  const [avgRating, setAvgRating] = useState(null);
  const hasMenuItems = shop?.menu_items.length > 0;
  const hasProducts = shop?.products.length > 0;

  const scrollToReviews = () => reviewsRef.current.scrollIntoView();

  const handlePlaceOrder = () => {
    if (local.isUserLoggedIn()) {
      dispatch(generalExtraActions.createCustomerOrder())
        .then(({payload: {link}}) => {
          navigate(`/web-order/${link.split('=').slice(-1)}/?area=${shop?.area?.id || ''}&category=${shop?.category?.id || ''}&shop=${shop?.id || ''}`,
          {state: {shop}});
        })
    } else {
      dispatch(authActions.logout());
      showLoginModal(true);
    }
  };

  useEffect(() => {
    pageNumber && dispatch(generalExtraActions.getCustomerReviews({shopId, params: {page: pageNumber}}));
    return () => {
      dispatch(generalActions.resetReviews());
    }
  }, [dispatch, shopId, pageNumber])


  useEffect(() => {
    const fetchData = async () => {
      try {
        setShop(await api.getShopDetail(shopId));
      } catch (error) {
        utils.showErrorToast(error);
      }
    }
    fetchData();
  }, [dispatch, shopId]);

  useEffect(()=>{
    if(shop) {
      setAvgRating(shop.average_rating);
    }
  },[shop]);

  hooks.usePageTitle(labels.VENDOR_DETAIL);
  hooks.useScrollToTop();

  return (
    <div className="vendorDetail">
        <section className="breadcrumbWrap">
          <div className="container">
            <Breadcrumb>
              <Breadcrumb.Item onClick={() => navigate(routes.HOME)}>Areas</Breadcrumb.Item>
              {shop?.category && <Breadcrumb.Item
                onClick={() => navigate(shop?.area?.id ? `/vendors/${shop?.area?.id}/?category=${shop?.category?.id}` : routes.HOME)}
              >
                {shop?.category.name}
              </Breadcrumb.Item>
              }
              <Breadcrumb.Item active>{shop?.name}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </section>
        <section className="vendorDetailsWrap">
          <div className="container">
            <div className="row">
              <div className="detailsWrap col-lg-6">
                <div>
                  <VendorInfo shop={shop} reviews={reviews} avgRating={avgRating} scrollToReviews={scrollToReviews}/>
                  {shop ? (
                    <ul id="vListData">
                      <li className="show">
                        {shop?.is_delivery_offered &&
                          <div className="dService">
                            <span>Delivery Service</span>
                            <span><span><img src={IconCheckCircle} alt=""/></span></span>
                          </div>
                        }
                        {shop?.is_pickup_offered &&
                          <div className="dService">
                            <span>Pickup Service</span>
                            <span><span><img src={IconCheckCircle} alt=""/></span></span>
                          </div>
                        }
                      </li>
                      <li className="show">
                        <span><img src={IconLocation} alt=""/></span>
                        <span>{shop?.address?.address || "Not Available"}</span>
                      </li>
                      <li className="show">
                        <Schedule shop={shop}></Schedule>
                      </li>
                      <li className="show">
                        <span><img src={IconTelephone} alt=""/></span>
                        {shop?.phone_number ?
                          <Link id="vendorPhoneNumber" to={`tel:${shop?.phone_number}`}>{shop?.phone_number}</Link>
                          : <span>Not Available</span>
                        }
                      </li>
                    </ul>
                  ) : (
                    <Placeholder id="vListData" as="span" animation="glow">
                      <li className="show">
                        <Placeholder xs={3} className="dServicePlaceholder"/> {"  "} <Placeholder xs={3}
                          className="dServicePlaceholder"/>
                      </li>
                      <li className="show">
                        <Placeholder className="placeholder" xs={4}/>
                      </li>
                      <li className="show">
                        <Placeholder className="placeholder" xs={4}/>
                      </li>
                      <li className="show">
                        <Placeholder className="placeholder" xs={3}/>
                      </li>
                    </Placeholder>
                  )}
                </div>
                <div className="CTA d-flex align-content- justify-content-between">
                  <LoaderBtn
                    id={"placeOrder"} loading={generalLoading} disabled={generalLoading || !shop}
                    title={labels.PLACE_YOUR_ORDER} onClick={handlePlaceOrder}
                  />
                </div>

              </div>
              <div className="vendorMapWrap col-lg-6">
                <div className="holder">
                  {shop ? (shop.address?.coordinates ?
                      <Map pin={shop.address.coordinates}/> :
                      <img src={LocationNotFoundImage} alt=""/>
                  ) : (
                    <Placeholder as="h1" animation="glow" className="placeholder">
                      <Placeholder xs={6} className="map-container"/>
                    </Placeholder>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="allSections">
          {((hasMenuItems || hasProducts) && (
            <section>
              <div className="container">
                  <div className="detailSections">
                    {hasMenuItems && (
                      <section>
                        <h2>Menus <span>/ Gallery</span></h2>
                        <MenuCarousel menuItems={shop?.menu_items} name={shop?.name}></MenuCarousel>
                        <MenuLink menuItems={shop?.menu_items}></MenuLink>
                      </section>
                    )}
                    {hasProducts && (
                      <section>
                        <h2>Offered <span>items/Services</span></h2>
                        <ul className="allItems">
                          {shop?.products?.map((product, index) => {
                            return (
                              <li key={index}>
                                <div className="productImageWrap">
                                  <img className={product?.image_url ? "" : "default"} src={product?.image_url || Basket}/>
                                </div>
                                <div className="productDetail">
                                  <span className="name">{product.name}</span>
                                  <span className="price">RS. <span>{product.price || "Not Listed"}</span></span>
                                </div>
                              </li>)
                          })}
                        </ul>
                      </section>
                    )}
                  </div>
              </div>
              
            </section>
          ))}

          <section ref={reviewsRef}>
            <ReviewSection
              shopId={shopId} shopName={shop?.name} avgRating={avgRating} setAvgRating={setAvgRating} reviews={reviews}
              pageNumber={pageNumber} setPageNumber={setPageNumber} loading={generalLoading}
              className={`reviewWrap ${hasMenuItems || hasProducts ? "" : "custom-padding"}`}
            />
          </section>
        </div>
    </div>
  );
}

export default VendorDetail;

