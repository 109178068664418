import * as Yup from 'yup';

export const schemas = {
  login: Yup.object().shape({
    phoneNumber: Yup.string()
      .required('Phone Number is required')
      .matches(/^3[0-9]{9}$/, 'Please enter a valid Phone Number.'),
  }),
  otpVerify: Yup.object().shape({
    otp: Yup.string()
      .required('OTP is required')
      .matches(/^\d{6}$/, 'Invalid OTP'),
  }),
  customerAddress: Yup.object().shape({
    existing_address: Yup.string()
      .required('Either choose Existing Address or add new Address'),
    address: Yup.string().when('existing_address', {
      is: '0',
      then: () => Yup.string().required('Address is required'),
      otherwise: () => Yup.string()
    }),
    area: Yup.string().when('existing_address', {
      is: '0',
      then: () => Yup.string().required('Area is required').test('not-zero', 'Area is required', value => value !== '0'),
      otherwise: () => Yup.string()
    })
  }),
  order: Yup.object().shape({
    shop: Yup.string()
      .notOneOf(['0'], 'Shop is required')
      .required('Shop is required'),
    order_text: Yup.string(),
    order_audio: Yup.mixed().notRequired(),
    transport: Yup.string()
      .required('Transport is required'),
  }),
  webOrder: Yup.object().shape({
    shop: Yup.string()
      .notOneOf(['0'], 'Shop is required')
      .required('Shop is required'),
    order_text: Yup.string(),
    order_audio: Yup.mixed().notRequired(),
    transport: Yup.string()
      .required('Transport is required'),
    existing_address: Yup.string().when('transport', {
        is: 'DELIVERY',
        then:() => Yup.string()
          .required('Please select a delivery address to place order.'),
        otherwise:()=> Yup.string().notRequired(),
      }),
  }),
  newAddress: Yup.object().shape({
    sector: Yup.string().notRequired(),
    block: Yup.string().notRequired(),
    houseNumber: Yup.string().required('House Number is Required'),
  }),
  write_review: Yup.object().shape({
    rating: Yup.number()
      .min(1, 'Rating is required.')
      .max(5, 'Rating cannot be more than 5.')
  }),
};
