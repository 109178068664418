import React, { useEffect, useRef, useState } from 'react';
import 'pages/vendors/_vendors.scss';
import { hooks, labels, local, utils } from "common";
import { useDispatch, useSelector } from "react-redux";
import { generalExtraActions } from "reduxStore/actions";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Categories, SortingDropDown, VendorsSection } from "components";
import { LocationConsentModal } from 'modals';
import { Dropdown, DropdownButton, Form, Spinner } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import InfiniteScroll from "react-infinite-scroll-component";
import { generalActions } from 'reduxStore/reducers/generalSlice';
import { authActions } from 'reduxStore/reducers/authSlice';

function Vendors() {
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const {id: areaId} = useParams()
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    shops,
    categories,
    areas,
    nextPage,
    loading,
    permission: consent,
    shopStatus,
  } = useSelector((state) => state.general);
  const categoryParam = searchParams.get('category');
  const searchParam = searchParams.get('search') ?? '';
  const sortParam = searchParams.get('sort');
  const gridParam = searchParams.get('grid');
  const currentArea = areas?.find(area => area.id == areaId);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLoading, setIsLoading] = useState(loading);
  const [currentCategoryId, setCurrentCategoryId] = useState(categoryParam);
  const [search, setSearch] = useState(searchParam ? searchParam : '');
  const [selectedValue, setSelectedValue] = useState(
    sortParam == "distance" ? labels.NEAREST_SHOPS : labels.SORT_ALPHABETICALLY
  );
  const [pin, setPin] = useState({latitude: null, longitude: null});
  const [sortByDistance, setSortByDistance] = useState(null);
  const [clear, setClear] = useState(false);
  const [gridView, setGridView] = useState(gridParam === 'true');
  const [showModal, setShowModal] = useState(false);
  const [categoryMap, setCategoryMap] = useState({});
  
  const makeParams = () => {
    let params = {}
    if (nextPage) {
      params['page'] = nextPage;
    }
    if (search) {
      params['search'] = search;
    }
    if (currentCategoryId) {
      params['category'] = currentCategoryId;
    }
    if (
      selectedValue == labels.NEAREST_SHOPS &&
      sortByDistance &&
      pin?.latitude &&
      pin?.longitude
    ) {
      params["latitude"] = pin.latitude;
      params["longitude"] = pin.longitude;
    }
    return params;
  }

  const addParams = () => {
    const paramsToUpdate = {};
    if (currentCategoryId !== categoryParam) {
      paramsToUpdate.category = currentCategoryId;
    }
    if (searchParam !== search) {
      paramsToUpdate.search = search;
    }

    if (Object.keys(paramsToUpdate).length > 0) {
      setSearchParams((prevParams) => {
        const updatedParams = new URLSearchParams(prevParams);
        for (const [key, value] of Object.entries(paramsToUpdate)) {
          if (value === null || value === '') {
            updatedParams.delete(key);
          } else {
            updatedParams.set(key, value);
          }
        }
        return updatedParams;
      });
    }
  }

  useEffect(() => {
    navigator.permissions &&
      navigator.permissions.query({ name: 'geolocation' }).then(permission => {
        if (permission.state === "prompt") {
          setSelectedValue(labels.SORT_ALPHABETICALLY);
          consent === null && setShowModal(true)
          // Handling user location access revocation from browser settings after permission grant.
          if(!pin.longitude && !pin.latitude && local.isLocationAllowed()) {
            dispatch(generalExtraActions.getShopsFromStart({areaId, params: makeParams()}));
          }
          local.removeIsLocationAllowed();
        } else if (permission.state === "granted" && sortParam !== "name") {
          sortParam!=='name' && setSelectedValue(labels.NEAREST_SHOPS);
          dispatch(generalActions.setPermission(true));
        } else if (permission.state === "denied" && sortParam !== "distance") {
          dispatch(generalActions.setPermission(false));
          setSelectedValue(labels.SORT_ALPHABETICALLY);
          setSortByDistance(false);
          setPin({latitude: "", longitude: ""});
          local.removeIsLocationAllowed()
        }
      })
  }, []);


  const fetchNextPageData = () => {
    dispatch(generalExtraActions.getShops({areaId, params: makeParams()}));
  }

  const handelAreaChange = () => {
    if (local.isLocationAllowed()) {
      setSelectedValue(labels.NEAREST_SHOPS);
      setSortByDistance(true); 
    }
  }

  useEffect(() => {
    dispatch(generalExtraActions.getShopsFromStart.pending());
  }, [dispatch]);

  useEffect(() => {
    if (pin?.longitude) {
      selectedValue === labels.NEAREST_SHOPS
        ? setSortByDistance(true)
        : setSortByDistance(false);
    }
  }, [pin]);

  useEffect(()=>{
    setCategoryMap(utils.toArrayMappingById(categories));
  },[categories])

  useEffect(()=>{
    dispatch(generalExtraActions.getSearchCategories({area: areaId, search: search}));
    search ? setClear(true) : setClear(false);
  },[search])

  useEffect(() => {
    if(!loading) {
      const timer = setTimeout(() => {
        setIsLoading(loading);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setIsLoading(loading)
    }
  }, [loading])

  useEffect(addParams, [currentCategoryId, search]);

  useEffect(()=>{
    setCurrentCategoryId(categoryParam);
  },[categoryParam]);

  useEffect(()=>{
    searchParam!==null && setSearch(searchParam);
  },[searchParam]);

  useEffect(()=>{
    setGridView(gridParam === 'true');
  }, [gridParam]);

  useEffect(() => {
    if (!isMounted.current) return;

    if (!sortParam) {
      if (consent && pin?.latitude) {
        setSortByDistance(true);
        setSelectedValue(labels.NEAREST_SHOPS);
      } else if (!local.isLocationAllowed()) {
        setSortByDistance(false);
        setSelectedValue(labels.SORT_ALPHABETICALLY);
      }
    } else {
      setSortByDistance(sortParam === "distance");
      setSelectedValue(sortParam === "distance" ? labels.NEAREST_SHOPS : labels.SORT_ALPHABETICALLY);
    }
  }, [sortParam]);
  
  useEffect(() => {
      utils.scrollWindowToTop();
      if((pin.latitude && pin.longitude) || !local.isLocationAllowed()) {
        dispatch(generalExtraActions.getShopsFromStart({areaId, params: makeParams()}));
        if (!isMounted.current) {
          isMounted.current = true;
        }
      }
  }, [dispatch, search, currentCategoryId, sortByDistance]);

  useEffect(() => {
    if(searchParams.size == 0 && isMounted.current) {
      dispatch(generalExtraActions.getShopsFromStart({areaId, params: makeParams()}));
    }
    if(searchParam) {
      dispatch(generalExtraActions.getSearchCategories({area: areaId, search: search}));
    } else {
      dispatch(generalExtraActions.getCategories({area: areaId}));
    }
  }, [dispatch, areaId])

  useEffect(() => {
    dispatch(generalExtraActions.getAreas({isactive: 1}));
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  hooks.usePageTitle(`${currentArea?.area} ${labels.VENDORS}`)
  hooks.useScrollToTop();

  return (
    <div className={'vendors'}>
      <section className={`vendorsWrap vendorHead ${isScrolled && 'active'}`}>
        <div className="container">
          <div className="d-flex flex-column">
            <div className="filterWrap">
              <Form id="searchForm" className="d-flex gap-3 justify-content-between align-items-center"
                    onSubmit={(e) => {
                      e.preventDefault()
                    }}
              >
                <div className="selectAreaDropDown">
                  <DropdownButton
                    onSelect={handelAreaChange}
                    variant="secondary" 
                    size="sm" 
                    title={<span>{currentArea?.area}</span>}
                  >
                    {areas?.map((area) => {
                        return <Dropdown.Item>
                          <Link to={`/vendors/${area.id}${gridView ? '?grid=true' : ''}`}
                                className={`dropdown-item ${currentArea?.id == area.id && 'active'}`}
                                onClick={() => {
                                  setCurrentCategoryId(null);
                                  setSearch('');
                                }}
                          >
                            {area.area}
                          </Link>
                        </Dropdown.Item>
                      }
                    )}
                  </DropdownButton>
                </div>
                <div className="flex-grow-1 searchfield">
                  <DebounceInput
                    className="form-control"
                    placeholder={"Search..."}
                    debounceTimeout={-1}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  {clear && <icon onClick={()=>setSearch("")} class="clear-icon">&#x2715;</icon>}
                </div>
              </Form>
            </div>
          </div>
        </div>
        <section className="catNav" id="myDiv">
          <Categories
            categories={categories} setCategoryId={setCurrentCategoryId} currentCategoryId={currentCategoryId}
          />
        </section>
      </section>
      <section className='sortWrap'>
        <SortingDropDown
          selectedValue={selectedValue} setSelectedValue={setSelectedValue} setSortByDistance={setSortByDistance}
          setPin={setPin} gridView={gridView} setGridView={setGridView} shopsLength={shops?.length}
          setIsLoading={setIsLoading} sortParam={sortParam} setSearchParams={setSearchParams}
        />
      </section>
      <section>
        <div className="container vendors">
          <div className={`allCatWrap ${gridView ? "gridView" : ""}`}>
            {shops.length > 0 ? (
              <InfiniteScroll
                dataLength={shops?.length}
                next={fetchNextPageData}
                hasMore={!!nextPage}
                loader={null}>
                {currentCategoryId ? (
                  <VendorsSection category={categoryMap[currentCategoryId]} shops={shops}/>
                ) : (
                  categories
                    .map(category => (
                      <VendorsSection key={category.name} category={category} shops={shops}/>
                    ))
                )}
              </InfiniteScroll>
            ) : (!isLoading && shopStatus === "success" && <div>
                <h5 className="noResults">Oops! No results found.</h5>
                <p>
                  It seems we don’t have any shop matching your query.
                  Please try a different search term or explore our list of available vendors/shops.</p>
              </div>
            )}
            {isLoading && <div className='spinner'>
              <Spinner as="span" animation="border" role="status"/>
            </div>
            }
          </div>
        </div>
      </section>
      <LocationConsentModal show={showModal} setShow={setShowModal}/>
    </div>
  );
}

export default Vendors;

