import React, { useEffect, useState } from "react";
import "pages/vendorDetail/_vendorDetail.scss";
import moment from "moment";
import { ImgCustomer } from "assets/images";
import { ShowMoreText } from "components";
import { BsPencilSquare } from "react-icons/bs";
import { OverlayTrigger, Popover } from "react-bootstrap";
import StarRatings from "react-star-ratings";

function Review({ review, customerReview }) {
  const [edit, setEdit] = useState(false);
  const [displayDate, setDisplayDate] = useState(customerReview?.created);
  const isCustomerReview = customerReview && customerReview?.id == review?.id;
  const isEdited = review?.created !== review?.modified;

  useEffect(() => {
    if (customerReview?.created != customerReview?.modified) {
      setEdit(true);
      setDisplayDate(customerReview?.modified);
    }
  }, [customerReview]);

  return (
    <li>
      <div className="head">
        <div>
          <div className="avatar">
            <img src={ImgCustomer} alt="reviwer" />
          </div>
          <div className="nickName">{review?.phone_number}</div>
          <StarRatings
            starSpacing="2px"
            starDimension="14px"
            rating={isCustomerReview ? customerReview?.rating : review?.rating}
            starRatedColor="#6C5CE7CC"
            numberOfStars={5}
            name="rating"
          />
          <span className="rating">
            {isCustomerReview ? customerReview?.rating : review?.rating}
          </span>
        </div>
        <div className="dateArea">
          {moment(new Date(review?.created)).fromNow()}
          {(isEdited || (isCustomerReview && edit)) && (
            <div className="edited">
              <span className="divider"></span>
              <OverlayTrigger
                trigger="hover"
                rootClose
                placement="bottom"
                overlay={
                  <Popover
                    className="edited"
                    style={{
                      padding: "7px",
                      backgroundColor: "#2F2D2D",
                      color: "white",
                      fontSize: "11px",
                    }}
                  >
                    Edited:{" "}
                    {isCustomerReview
                      ? moment(displayDate).fromNow()
                      : moment(review?.modified).fromNow()}
                  </Popover>
                }
              >
                <span className="icon">
                  <BsPencilSquare />
                </span>
              </OverlayTrigger>
            </div>
          )}
        </div>
      </div>
      <div className="reviewText">
        <ShowMoreText
          text={isCustomerReview ? customerReview?.review : review?.review}
        />
      </div>
    </li>
  );
}

export default Review;

