import React, { useEffect, useState } from 'react';
import 'pages/order/_order.css';
import { Logo2 } from "../../assets/icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { api } from "../../api";
import { errors, errorsCodes, hooks, labels, routes, utils } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import { generalExtraActions } from "../../reduxStore/actions";
import OrderStep1 from "./OrderStep1";
import OrderStep2 from "./OrderStep2";
import { Button, Spinner } from "react-bootstrap";

function Order() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {token} = useParams();
  const {areas, categories, orderShops: shops, loading: generalLoading, orderShopsLoading} = useSelector((state) => state.general);
  const [searchParams, setSearchParams] = useSearchParams();
  const areaParam = searchParams.get('area')
  const categoryParam = searchParams.get('category')
  const shopParam = searchParams.get('shop')
  const [loading, setLoading] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [category, setCategory] = useState(categoryParam);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [customerDetail, setCustomerDetail] = useState({has_ongoing_order: false});
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [message, setMessage] = useState(null);
  const [errorMsg, setErrorMsg] = useState(errors.NO_ONGOING_ORDER);

  const handleOrderFormSubmit = async (values) => {
    if (!token) return;
    let fd = new FormData();
    fd.append('customer_address', selectedAddress.id);
    values.order_audio && fd.append('order_audio', values.order_audio);
    values.order_text && fd.append('order_text', values.order_text);
    fd.append('shop', values.shop);
    fd.append('transport', values.transport);
    setOrderLoading(true);
    try {
      const data = await api.createOrder(token, fd);
      setMessage(data.detail);
      setStep(3);
    } catch (error) {
      utils.showErrorToast(error)
    }
    setOrderLoading(false);
  };


  const handleAddressFormSubmit = async (values) => {
    if (!token) return;
    if (values.existing_address == 0) {
      setOrderLoading(true);
      try {
        const data = await api.createCustomerAddresses(token, values);
        dispatch(generalExtraActions.getCategories())
        setSelectedAddress(data);
        setStep(2);
      } catch (error) {
        utils.showErrorToast(error);
      }
      setOrderLoading(false);
    } else {
      setSelectedAddress(customerAddresses.find(ca => ca.id == values.existing_address))
      setStep(2);
    }
  };

  const handleGoBack = () => {
    navigate(shopParam ? `/vendor-detail/${shopParam}` : routes.HOME)
  };

  const renderOrderSteps = () => {
    if (loading || generalLoading || orderShopsLoading) {
      return <div id="error" className="d-flex flex-column gap-3">
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      </div>
    }
    if (!customerDetail?.has_ongoing_order) {
      return <div id="error" className="error-msg d-flex flex-column gap-3">
        {errorMsg}
        <Button variant="primary" onClick={handleGoBack}>Go Back</Button>
      </div>
    }
    if (step === 1) {
      return <OrderStep1
        areas={areas} customerAddresses={customerAddresses} customerDetail={customerDetail}
        handleFormSubmit={handleAddressFormSubmit} loading={orderLoading}
      />
    } else if (step === 2) {
      return <OrderStep2
        shopParam={shopParam} areaParam={areaParam} category={category} setCategory={setCategory}
        shops={shops} categories={categories} customerAddress={selectedAddress}
        handleFormSubmit={handleOrderFormSubmit} loading={orderLoading}
      />
    } else {
      return <div className="text-black d-flex flex-column gap-3">
        {message}
        <Button variant="primary" onClick={handleGoBack}>Go Back</Button>
      </div>
    }
  }
  useEffect(() => {
    if (!token) return;
    const fetchData = async () => {
      setLoading(true);
      try {
        const detail = await api.getCustomerDetail(token);
        setCustomerDetail(detail)
      } catch (error) {
        if (error?.resposne?.status === errorsCodes.FORBIDDEN_CODE) {
          setErrorMsg(errors.INVALID_LINK)
        }
        setLoading(false);
        return
      }
      try {
        const addresses = await api.getCustomerAddresses(token);
        setCustomerAddresses(addresses)
      } catch (error) {
        utils.showErrorToast(error)
      }
      setLoading(false);
    };
    fetchData();
  }, [dispatch, token]);

  useEffect(() => {
    areas === null && dispatch(generalExtraActions.getAreas());
  }, [dispatch, areas]);

  useEffect(() => {
    if (selectedAddress) {
      dispatch(generalExtraActions.getCategories())
      dispatch(generalExtraActions.getOrderShops({areaId: selectedAddress?.area}))
    }
  }, [dispatch, selectedAddress])

  hooks.usePageTitle(labels.ORDER)

  return (
    <div className="order">
      <div className="container">
        <div className="order-header">
          <img src={Logo2} alt="logo"/>
        </div>
        {renderOrderSteps()}
      </div>
    </div>
  );
}

export default Order;