// export const API_BASE_URL = 'https://stg-api.fatafat.io';
// export const API_BASE_URL = 'https://charmed-amazing-beagle.ngrok-free.app';
export const API_BASE_URL = 'https://api.fatafat.io';
// export const API_BASE_URL = 'http://127.0.0.1:8000';
export const MAP_BOX_CLIENT_ID = 'pk.eyJ1IjoibWFyeWFtc2hhaGlkIiwiYSI6ImNscXdkNzE5djAyNDgya3B6b283OTk0ZHIifQ.Urj1JIhqjuABGECAAsNb0w';


// STAGE
// export const GA_MEASUREMENT_ID = 'G-21807DMKTX'

// PROD
export const GA_MEASUREMENT_ID = 'G-R7G8ERMB7J'
