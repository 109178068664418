export const labels = {
  APP: 'Fatafat',
  LOGIN: 'Login',
  HOME: 'Home',
  VENDORS: 'Vendors',
  VENDOR_DETAIL: 'Vendor Detail',
  SELECT: '---select---',
  SUBMIT: 'Submit',
  UPDATE: 'Update',
  PREVIOUS: 'Previous',
  NEXT: 'Next',
  PLACE_YOUR_ORDER: 'Place Your Order',
  PLACE_ORDER: 'Place Order',
  PROCEED: 'Proceed',
  VERIFY: 'Verify',
  ORDER: 'Order',
  ADD_ADDRESS: 'Add Address',
  UPDATE_ADDRESS: 'Update Address',
  WRITE_REVIEW: 'Share Your Experience',
  EDIT_REVIEW: 'Edit Review',
  SORT_ALPHABETICALLY: 'Alphabetically (A-Z)',
  NEAREST_SHOPS: 'Nearest Shops',
  ALLOW: 'Allow',
  DENY: 'Deny',
};

export const clauses = {
  WRITE_REVIEW: 'Write a review'
};

export const messages = {
  ADDRESS_SUCCESS: 'Address Added Successfully!',
  UPDATE_SUCCES: 'Review Updated Successfully!',
  ADDRESS_UPDATE_SUCCESS: 'Address Updated Successfully!',
  ADDRESS_DELETE_SUCCESS: 'Address Deleted Successfully!',
}

export const globals = {
  TOAST_TIMER: 3000,
  PAGE_SIZE: 10,
  REVIEWS_PAGE_SIZE: 5,
  REVIEW_MAX_LENGTH: 500,
  LOGIN_TIMEOUT: 24 * 60 * 60 * 1000, // 24 hours in milliseconds
  DEFAULT_PIN: { // Bahria Town
    latitude: 31.3695,
    longitude: 74.1768,
  },
  DEFAULT_ADDRESS: {
    plot: null,
    block: null,
    sector: null,
    street: null,
  }
};

export const MONDAY = 1;
export const TUESDAY = 2;
export const WEDNESDAY = 3;
export const THURSDAY = 4;
export const FRIDAY = 5;
export const SATURDAY = 6;
export const SUNDAY = 0;

export const weekdays = {
  [MONDAY]: 'Monday',
  [TUESDAY]: 'Tuesday',
  [WEDNESDAY]: 'Wednesday',
  [THURSDAY]: 'Thursday',
  [FRIDAY]: 'Friday',
  [SATURDAY]: 'Saturday',
  [SUNDAY]: 'Sunday',
};

export const sortBy = [
  labels.NEAREST_SHOPS,
  labels.SORT_ALPHABETICALLY,
];

