import React, { useEffect, useRef } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { Form } from "react-bootstrap";
import { ShopProducts } from "components";
import { ImgCancel } from "assets/images";
import { BsFillMicFill } from "react-icons/bs";

function OrderItems({
                      products,
                      handleProductChange,
                      formik,
                      handleAudioDelete,
                    }) {
  const recorderRef = useRef(null);
  const recorderControls = useAudioRecorder();

  const handleRecordingComplete = (blob) => {
    formik.setFieldValue("order_audio", blob);
  };

  useEffect(() => {
    if (recorderRef.current) {
      const ele = recorderRef.current.querySelector(
        '[data-testid="ar_cancel"]'
      );
      ele?.setAttribute("title", "Stop recording");
      recorderRef.current.querySelector('.audio-recorder-mic')?.remove();
    }
  }, []);

  return (
    <div className="orderWrap">
      <div className="holder">
        <ShopProducts
          products={products}
          handleProductChange={handleProductChange}
          source={"Web"}
        />
        <Form.Control
          as="textarea"
          name="order_text"
          id="order-text"
          placeholder="Type or dictate your custom order here (e.g., milk - 1kg, oil - 2L)"
          value={formik.values.order_text}
          onChange={formik.handleChange}
          isInvalid={formik.touched.order_text && !!formik.errors.order_text}
        ></Form.Control>
        <div className={formik.values.order_audio && "voiceNoteHolder"}>
          {formik.values.order_audio && (
            <>
              <audio
                id="audio-play"
                src={formik.values.order_audio ? URL.createObjectURL(formik.values.order_audio) : null}
                className="audio-play"
                controls
              ></audio>
              <a id="audio-delete" className="audio-delete" onClick={handleAudioDelete}>
                <img width={20} height={20} src={ImgCancel} alt="x"/>
              </a>
            </>
          )}
        </div>
        <div className="text-danger items">
          {formik.touched.order_text && formik.errors.order_text}
        </div>
      </div>
      <div ref={recorderRef} className="audioContainer">
        <a
          className="btn-AddVoice"
          onClick={recorderControls.isRecording ? () => {} : recorderControls.startRecording}
          style={recorderControls.isRecording ? {width: "240px"} : {}}
        >
         {!recorderControls.isRecording && <BsFillMicFill className="icon"/>}
          <AudioRecorder
            recorderControls={recorderControls}
            onRecordingComplete={handleRecordingComplete}
            downloadOnSavePress={false}
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
            downloadFileExtension="mp3"
          />
          {!recorderControls.isRecording && <span>Add Voice Note</span>}
        </a>
      </div>
    </div>
  );
}

export default OrderItems;

